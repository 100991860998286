import React from "react"
import ResetPassword from "~sections/Account/ResetPassword"
import { PageWrapper } from "~components/Core"

const header = {
  headerClasses: "d-none",
  containerFluid: false,
}

export default function Forgot() {
  return (
    <PageWrapper headerConfig={header}>
      <ResetPassword />
    </PageWrapper>
  )
}
